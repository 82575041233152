<template>
  <div class="agreement">
    <div class="top">
      <div class="btnbox">
        <el-button
          type="primary"
          icon="el-icon-plus"
          class="blue-btn"
          @click="add"
          >添加协议</el-button
        >
      </div>
    </div>
    <div class="content">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
          type="index"
          width="100"
          label="编号"
        ></el-table-column>
        <el-table-column prop="agreementName" label="协议名称">
        </el-table-column>
        <el-table-column prop="createdTime" label="创建时间"> </el-table-column>
        <el-table-column prop="updatedTime" label="修改时间"> </el-table-column>
        <el-table-column prop="cityPathName" label="状态">
          <template slot-scope="{ row }">
            <span :style="{ color: row.isDel === 0 ? '#5E83F5' : '#EE4242' }">{{
              row.isDel === 0 ? "使用中" : "已删除"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="内容">
          <template slot-scope="{ row }">
            <el-button class="text-btn" type="text" @click="checkImg(row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-popconfirm
              title="确认删除该协议吗？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(scope.row)"
            >
              <el-button class="textred-btn" slot="reference">删除</el-button>
            </el-popconfirm>
            <el-button
              class="textblue-btn"
              type="text"
              @click="checkDetail(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination
        v-show="total > queryParams.pageSize"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="init"
      />
    </div>
    <!-- 查看内容 -->
    <el-dialog
      :visible.sync="IMgdialogVisible"
      title="内容"
      width="40%"
      :before-close="handleClose"
      destroy-on-close
      :show-close="false"
    >
      <div slot="title" class="title">
        <p>协议内容</p>
        <span @click="handleClose"><i class="el-icon-close"></i></span>
      </div>
      <div class="imgBox">
        <div class="ql-container ql-snow">
          <div class="ql-editor">
            <div v-html="ruchContent"></div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAgreementList, deleteAgreement } from "@/api/login";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      tableData: [],
      total: 0, //总数
      ruchContent: null,
      IMgdialogVisible: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    //获取协议列表
    async init() {
      const res = await getAgreementList(this.queryParams);
      this.tableData = res.result.records;
      this.total = res.result.total;
    },
    // 查看内容
    checkImg(obj) {
      this.ruchContent = obj.info;
      this.IMgdialogVisible = true;
    },
    handleClose() {
      this.IMgdialogVisible = false;
    },
    // 删除协议
    async cancelOrder(item) {
      // console.log(item, status, index);
      const params = {
        agreementName: item.agreementName,
        id: item.id,
      };
      await deleteAgreement(params);
      this.$message.success("协议删除成功");
      // this.$refs[`popover-${index}`].doClose();
      this.init();
    },
    // 编辑
    checkDetail(row) {
      this.$router.push({
        path: "/newagreement",
        query: {
          id: row.id,
          name: row.agreementName,
        },
      });
    },
    // 添加
    add() {
      this.$router.push({
        path: "/newagreement",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.agreement {
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0000000d;
    .querybox {
      width: 90%;
      ::v-deep .el-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .el-col {
          display: flex;
          align-items: center;
          p {
            color: #4e5969;
            line-height: 22px;
            margin-right: 16px;
            white-space: nowrap;
          }
          .el-input,
          .el-range-editor {
            width: 256px;
          }
          input::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #86909c;
          }
          .el-input__inner,
          .el-range-input {
            background: #f7f8fa;
            border: none;
            color: #86909c;
          }
          .tabul {
            margin: 16px 0;
            li {
              margin-right: 24px;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .btnbox {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      // flex-direction: column;
      padding-bottom: 10px;
      .el-button {
        padding: 5px 0;
        width: 84px;
        margin-right: 12px;
      }
    }
  }
  .content {
    padding-top: 20px;
    .logo {
      display: flex;
      justify-content: center;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
  ::v-deep(.el-dialog) {
    .el-dialog__title {
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
    }
    .el-dialog__body {
      display: flex;
      align-items: center;
      justify-content: center;
      .imgBox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        img {
          // width: 40%;
          // height: auto;
        }
      }
      .OutBtnBox {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
  .textred-btn {
    padding: 0 5px;
  }
}
</style>
